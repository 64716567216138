<template>
  <div class="about">
    <!-- <div class="top_icons">
      <div class="text1">一道云</div>
      <div class="top_navigation">
        <div class="text2">产品服务</div>
        <div class="text2">解决方案</div>
        <div class="text2">资讯资源</div>
        <div class="text2">关于我们</div>
      </div>
    </div> -->
    <myheader></myheader>
    <div class="top_img">
      <!-- <img src="../assets/aboutps/top.png"> -->
      <div style="text-align: center">
        <div class="top_text1">ABOUT US</div>
        <div class="top_text2">关于我们</div>
        <div class="top_text3">专业、品质、服务，是我们一贯的追求</div>
      </div>
      <div class="services wow animate__fadeInUp">
        <div class="s_text1">一 切 以 服 务 为 中 心</div>
        <div class="s_text2">ALL SERVICE-CENTERED</div>
        <div class="circles">
          <div>
            <div class="circle"><img src="../assets/aboutps/kehu.png" /></div>
            <div class="c_text1">客户至上</div>
          </div>
          <div>
            <div class="circle"><img src="../assets/aboutps/team.png" /></div>
            <div class="c_text1">团队协作</div>
          </div>
          <div>
            <div class="circle"><img src="../assets/aboutps/integrity.png" /></div>
            <div class="c_text1">激情诚信</div>
          </div>
        </div>
      </div>
      <div class="itd">
        <div class="itd_text1 wow animate__fadeInLeft">公司简介</div>
        <div class="itd_text2 wow animate__fadeInLeft">COMPANYPROFILE</div>
        <div class="itd_text3 wow animate__fadeInUp">
          江苏一道云科技发展有限公司成立于2015年，是一家致力培养新一代信息技术产业人才与构建产教融合新生态的科技公司。公司自主研发了教学实训平台、技能竞赛平台和云资源管理平台，开发项目化课程及相关模块化教学资源，为高职专科、职教本科、应用型本科在新工科、新一代信息技术专业群领域提供“岗课赛证”综合育人一体化解决方案。
          <div class="itd_text4">
            公司是全国职业院校技能大赛云计算赛项合作企业、首届世界职业院校技能大赛云计算赛项合作企业、2020年人社部推荐的职业技能培训线上平台机构、2021年工信部教育考试中心工业和信息化线上培训工程合作企业、教育部1+X云计算平台运维与开发职业技能等级证书培训企业；参与高职专科云计算技术应用专业国家教学标准、职教本科云计算技术专业国家教学标准、云计算工程技术人员国家职业技术技能标准的编制工作，参与教育部1+X云计算平台运维与开发证书标准编制与修订工作；是全国云计算领域“岗课赛证”联盟理事长单位、全国云计算大数据职教集团副理事长单位、华为鲲鹏教育生态合作伙伴。
          </div>
        </div>
        <div class="itd_text5 wow animate__fadeInRight"><img src="../assets/aboutps/jianjie.png" /></div>
      </div>
      <div class="devel">
        <div class="wow animate__fadeInUp">
          <div class="process">
            <div class="devel_text1">发展历程</div>
            <div class="devel_text2">DEVELOPMENT HISTORY</div>
          </div>
          <div class="process_text1">
            <div class="text1_4">
              <div class="text1_5"></div>
              <div style="width: 80%;">
                <div style="display: flex">
                  <div class="text1_1"></div>
                  <div class="text1_2">2015</div>
                  <div class="text1_3">江苏一道云科技发展有限公司成立。</div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2016</div>
                  <div class="text1_3">全国云计算技术与应用专业首届师资培训班，担任全国云计算大数据职业教育集团副理事长单位，全国云计算技术与应用专业教材编审委员会成员。</div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2017</div>
                  <div class="text1_3">全国各省职业院校技能大赛高职组云计算赛项合作企业。</div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2018</div>
                  <div class="text1_3">综合育人实训平台（斗学网）上线。</div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2019</div>
                  <div class="text1_3">山东省职业院校现代学徒制示范项目合作企业。</div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2020</div>
                  <div class="text1_3">云计算竞赛管理系统上线，公司成为人社部推荐的职业技能培训线上平台机构之一。</div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2021</div>
                  <div class="text1_3">
                    工信部教育考试中心工业和信息化线上培训工程合作企业，参与高职专科云计算技术应用、职教本科云计算技术专业教学标准的编制工作，
                    参与人社部工信部《云计算工程技术人员国家职业技术技能标准》的编制工作。
                  </div>
                </div>
                <div style="display: flex; margin-top: 60px">
                  <div class="text1_1"></div>
                  <div class="text1_2">2022</div>
                  <div class="text1_3">2022年全国职业院校技能大赛云计算赛项合作企业，首届世界职业院校技能大赛云计算赛项合作企业。</div>
                </div>
              </div>
            </div>
            <div class="text5_1">合作院校380+ / 教材资源20+  / 培养学生32000+</div>
          </div>
        </div>
      </div>
      <div>
        <div class="honor wow animate__fadeInUp">
          <div class="honor_text1">荣誉资质</div>
          <div class="honor_text2">HONOR AND QUALIFICATION</div>
          <div class="honor1">
            <div class="honor1_img"><img src="../assets/aboutps/rongyu.png" /></div>
            <div class="honor1_text1">人力资源社会保障部推荐54家职业技能培训线上平台之一</div>
          </div>
          <div style="display: flex; justify-content: space-around">
            <div class="honor2">
              <div class="honor1_img"><img src="../assets/aboutps/rongyu.png" /></div>
              <div class="honor1_text1">华为鲲鹏教育行业合作伙伴</div>
            </div>
            <div class="honor2">
              <div class="honor1_img"><img src="../assets/aboutps/rongyu.png" /></div>
              <div class="honor1_text1">海康威视教育合作伙伴</div>
            </div>
            
          </div>
          <div style="display: flex; justify-content: space-between">
            <div class="honor2">
              <div class="honor1_img"><img src="../assets/aboutps/rongyu.png" /></div>
              <div class="honor1_text1">全国云计算大数据职教集团副理事长单位</div>
            </div>
            <div class="honor2">
              <div class="honor1_img"><img src="../assets/aboutps/rongyu.png" /></div>
              <div class="honor1_text1">世界职业院校技能大赛云计算赛项合作企业</div>
            </div>
            <div class="honor2">
              <div class="honor1_img"><img src="../assets/aboutps/rongyu.png" /></div>
              <div class="honor1_text1">全国职业技能大赛云计算赛项合作企业</div>
            </div>
          </div>
          <div class="certificate">
            <div class="certificate1"><img src="../assets/aboutps/gaoxin.png" /></div>
            <div class="certificate1"><img src="../assets/aboutps/qiyie.png" /></div>
          </div>
          <div class="cert">
            <div class="certificate2"><img src="../assets/aboutps/jishu.png" /></div>
            <div class="certificate2"><img src="../assets/aboutps/jishu.png" /></div>
            <div class="certificate2"><img src="../assets/aboutps/jishu.png" /></div>
            <div class="certificate2"><img src="../assets/aboutps/jishu.png" /></div>
          </div>
        </div>
      </div>
      <div class="specl wow animate__fadeInUp" hidden>
        <div class="specl_text1">专家智库</div>
        <div class="specl_text2">SPECLALIST</div>
        <div class="specl1_1">
          <div class="specl1" :id="'specl' + index" v-for="(list, index) in data" :key="index">
            <div class="specl2">
              <img :src="cdn + list.via" alt="" srcset="" />
              <div class="specl3_text4"></div>
            </div>
            <div class="specl3">
              <div class="specl3_text1" :id="'specl4_' + index">{{ list.name }}</div>
              <div class="specl3_text2">{{ list.position }}</div>
              <div class="specl3_text3" :id="'specl5_' + index">{{ list.introduction }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="map" >
        <div class="maps">
          <div class="amap wow animate__fadeInLeft" hidden></div>
          <div class="map1 wow animate__fadeInRight">
            <div class="map1_text2">联系我们</div>
            <div style="margin-left: 100px; margin-top: 30px">
              <div class="map2">
                <div class="map2_text1"><img src="../assets/aboutps/compony.png" /></div>
                <div class="map2_text2">公司 : 江苏一道云科技发展有限公司</div>
              </div>
              <div class="map2_1">
                <div class="map2_text1"><img src="../assets/aboutps/phone.png" /></div>
                <div class="map2_text2">电话 : 0510-85730086</div>
              </div>
              <div class="map2_1">
                <div class="map2_text1"><img src="../assets/aboutps/address.png" /></div>
                <div class="map2_text2">地址 : 江苏省无锡市滨湖区太湖西大道2188号200室</div>
              </div>
              <div class="map2_1">
                <div class="map2_text1"><img src="../assets/aboutps/email.png" /></div>
                <div class="map2_text2">电子邮箱 : jsydy@1daoyun.com</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <mybottom></mybottom>
    </div>
  </div>
</template>
<script>
import myheader from '@/components/Header.vue';
import mybottom from '@/components/Bottom.vue';
export default {
  components: { myheader, mybottom },
  data() {
    return {
      message: 'hello world',
      data: [],
    };
  },
  mounted() {
    var wow = new this.WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    });
    wow.init();
  },
  created() {
    this.loadArticleCategory();
  },
  methods: {
    async loadArticleCategory() {
      let reqData = { searchText: '', startTime: '', endTime: '' };
      let reqParam = { pageNumber: '1', pageSize: '6' };
      let searchUrl = `/experts/search/${reqParam.pageNumber}/${reqParam.pageSize}`;
      let result = await this.axios.post(searchUrl, reqData);
      console.log(result);
      this.data = result.data.data;
      console.log(this.data);
    },
  },
};
</script>

<style>
.top_icons {
  display: flex;
  justify-content: center;
}
.text1 {
  color: #2e71fe;
  font-weight: bold;
  font-size: 18px;
  margin-right: 200px;
}
.top_navigation {
  display: flex;
}
.text2 {
  margin-right: 50px;
  font-weight: bold;
}
.top_img {
  width: 100%;
  height: 420px;
  /* opacity: 1; */
  background-image: url('../assets/aboutps/top.png');
  background-position: center;
  background-repeat: no-repeat;
  /* background-size: 100% 100%; */
}
.top_text1 {
  font-size: 60px;
  /* font-weight: 400; */
  color: rgb(209, 200, 200);
  opacity: 0.5;
  padding-top: 80px;
}
.top_text2 {
  font-size: 46px;
  /* font-weight: 400; */
  color: white;
}
.top_text3 {
  font-size: 18px;
  /* font-weight: 300; */
  color: white;
  margin-top: 20px;
  opacity: 0.8;
}
.services {
  border: 1px solid rgb(233, 229, 229);
  width: 1000px;
  height: 240px;
  background-color: white;
  margin: 0 auto;
  margin-top: 70px;
  box-shadow: 0 15px 30px rgb(199, 197, 197, 0.5);
  text-align: center;
}
.circles {
  width: 650px;
  margin: auto;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.s_text1 {
  margin-top: 25px;
  font-size: 24px;
  /* font-weight: 700; */
  color: black;
}
.s_text2 {
  font-size: 14px;
  /* font-weight: 400; */
  opacity: 0.8;
  color: rgb(209, 200, 200);
  margin-top: 10px;
}
.circle {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  border: 2px solid #2e71fe;
  padding: 17px 15px;
  /* margin-left: 100px; */
}
.circle img {
  width: 42px;
}
.c_text1 {
  font-size: 18px;
  /* font-weight: normal; */
  color: black;
  /* margin-left: 100px; */
}
.itd {
  width: 1200px;
  margin: auto;
  display: flex;
  margin-top: 120px;
  justify-content: center;
}
.itd_text1 {
  width: 50px;
  color: #2e71fe;
  font-size: 50px;
  font-weight: 550;
  line-height: 60px;
}
.itd_text2 {
  font-size: 30px;
  font-weight: 700;
  writing-mode: tb-rl;
  opacity: 0.1;
  margin-left: -18px;
  margin-top: 10px;
}
.itd_text3 {
  width: 600px;
  text-indent: 2em;
  font-size: 16px;
  font-weight: 550;
  color: black;
  /* padding: 18px; */
  text-align: left;
  line-height: 30px;
  margin-left: 40px;
  margin-top: 5px;
}
.itd_text4 {
  text-indent: 2em;
  margin-top: 40px;
}
.itd_text5 img {
  width: 380px;
  height: 290px;
  margin-left: 70px;
}
.devel {
  margin-top: 110px;
  height: 1000px;
  background-image: url('../assets/aboutps/diceng.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-align: center;
}
.process {
  width: 950px;
  text-align: center;
  margin: auto;
}
.devel_text1 {
  padding-top: 50px;
  font-size: 28px;
  font-weight: 550;
  /* text-align: center; */
  color: black;
}
.devel_text2 {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.3;
  margin-top: 10px;
}
.process_text1 {
  width: 1200px;
  height: 770px;
  margin: auto;
  background-color: white;
  margin-top: 35px;
}
.text1_1 {
  margin-left: -3.5px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  border: 2px solid #2e71fe;
  background-color: #2e71fe;
  margin-top: 10px;
}
.text1_2 {
  /* font-weight: 500; */
  margin-left: 20px;
  /* margin-top: 40px; */
  font-size: 16px;
}
.text1_3 {
  font-size: 16px;
  font-weight: 550;
  color: black;
  margin-left: 60px;
  /* margin-top: 40px; */
  text-align: left;
}
.text1_4 {
  padding-top: 50px;
  display: flex;
  justify-content: center;
  /* margin-left: 176px; */
}
.text1_5 {
  margin-top: 10px;
  height: 640px;
  width: 1px;
  background-color: rgb(216, 217, 220);
}
.text5_1 {
  margin-top: 70px;
  color: #2e71fe;
  font-size: 18px;
  font-weight: 600;
  word-spacing: 10px;
}
.honor {
  width: 1200px;
  /* height: 450px; */
  margin-right: auto;
  margin-left: auto;
  /* border: 1px solid rgb(109, 108, 108); */
  text-align: center;
}
.honor_text1 {
  padding-top: 70px;
  font-size: 28px;
  font-weight: 550;
  text-align: center;
  color: black;
}
.honor_text2 {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.3;
  margin-top: 10px;
}
.honor1 {
  width: fit-content;
  padding: 20px 46px;
  margin: auto;
  margin-top: 40px;
  display: flex;
  border: 1px solid rgb(255, 155, 58);
  box-shadow: 0px 0px 4px rgb(255, 155, 58);
}
.honor1_img {
  width: 24px;
  height: 22px;
  margin-right: 10px;
}
.honor1_text1 {
  font-size: 16px;
  font-weight: 500;
  color: black;
}
.honor2 {
  width: fit-content;
  padding: 20px 24px;
  margin: auto;
  margin-top: 30px;
  display: flex;
  border: 1px solid rgb(255, 155, 58);
  box-shadow: 0px 0px 4px rgb(255, 155, 58);
}
.honor3 {
  margin-top: 25px;
  width: 300px;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(255, 155, 58);
  box-shadow: 0px 0px 4px rgb(255, 155, 58);
}
.honor4 {
  margin-top: 25px;
  width: 300px;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(255, 155, 58);
  box-shadow: 0px 0px 4px rgb(255, 155, 58);
}
.honor5 {
  margin-top: 25px;
  width: 300px;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(255, 155, 58);
  box-shadow: 0px 0px 4px rgb(255, 155, 58);
}
.honor6 {
  margin-top: 25px;
  width: 330px;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  border: 1px solid rgb(255, 155, 58);
  box-shadow: 0px 0px 4px rgb(255, 155, 58);
}
.certificate {
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  /* border: 1px solid rgb(109, 108, 108); */
}
.certificate1 img {
  width: 285px;
  height: 203px;
}
.cert {
  display: flex;
  justify-content: space-around;
  width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 70px;
}
.certificate2 img {
  width: 285px;
  height: 384px;
}
.specl {
  width: 100%;
  height: 800px;
  background-color: #f8f8f8;
  text-align: center;
}
.specl1_1 {
  flex-wrap: wrap;
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-around;
}
.specl_text1 {
  padding-top: 50px;
  font-size: 28px;
  font-weight: 550;
  /* text-align: center; */
  color: black;
}
.specl_text2 {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.3;
  margin-top: 10px;
}
.specl1 {
  display: flex;
  width: 380px;
  height: 200px;
  margin-top: 65px;
}
.specl1:hover {
  background-color: white;
}
.specl2 {
  display: flex;
}
.specl2 img {
  width: 153px;
  height: 200px;
}
.specl3 {
  margin-left: 15px;
  text-align: left;
}
.specl3_text1 {
  font-size: 18px;
  font-weight: 600;
  color: #2e71fe;
  margin-top: 110px;
}
.specl3_text2 {
  font-size: 16px;
  font-weight: 550;
  color: black;
  margin-top: 10px;
}
.specl3_text3 {
  display: none;
  font-size: 14px;
  font-weight: 500;
  color: rgb(77, 75, 75);
  margin-top: 15px;
}
.specl3_text4 {
  width: 26px;
  height: 26px;
  background-color: #2e71fe;
  margin-left: -15px;
  margin-top: 185px;
}
#specl0:hover #specl4_0 {
  margin-top: 15px;
}
#specl0:hover #specl5_0 {
  display: block;
}
#specl1:hover #specl4_1 {
  margin-top: 15px;
}
#specl1:hover #specl5_1 {
  display: block;
}
#specl2:hover #specl4_2 {
  margin-top: 15px;
}
#specl2:hover #specl5_2 {
  display: block;
}
#specl3:hover #specl4_3 {
  margin-top: 15px;
}
#specl3:hover #specl5_3 {
  display: block;
}
#specl4:hover #specl4_4 {
  margin-top: 15px;
}
#specl4:hover #specl5_4 {
  display: block;
}
#specl5:hover #specl4_5 {
  margin-top: 15px;
}
#specl5:hover #specl5_5 {
  display: block;
}
.map {
  width: 100%;
  height: 450px;
}
.maps {
  display: flex;
  justify-content: center;
}
.map1 {
  padding: 20px;
  width: 500px;
  height: 450px;
  background-color: white;
}
.map1_text1 {
  margin-left: 125px;
  width: 295px;
  height: 115px;
  font-size: 70px;
  font-weight: 600;
  color: #2e71fe;
  box-shadow: 0px 0px 10px rgb(220, 220, 220);
  text-align: center;
}
.map1_text2 {
  font-size: 30px;
  font-weight: 600;
  color: black;
  margin-left: 100px;
  margin-top: 40px;
}
.map2 {
  display: flex;
}
.map2_1 {
  display: flex;
  margin-top: 20px;
}
.map2_text1 {
  margin-right: 12px;
}
.map2_text2 {
  font-size: 16px;
  color: black;
}
.amap {
  height: 450px;
  width: 1052px;
  margin-left: -400px;
  background-image: url('../assets/aboutps/map.png');
}
</style>
